// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-jsx": () => import("../src/i18n/redirect.jsx" /* webpackChunkName: "component---src-i-18-n-redirect-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-atlantic-forest-jsx": () => import("../src/pages/atlantic-forest.jsx" /* webpackChunkName: "component---src-pages-atlantic-forest-jsx" */),
  "component---src-pages-contact-jsx": () => import("../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kikkerland-jsx": () => import("../src/pages/kikkerland.jsx" /* webpackChunkName: "component---src-pages-kikkerland-jsx" */),
  "component---src-pages-page-2-jsx": () => import("../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-pages-people-jsx": () => import("../src/pages/people.jsx" /* webpackChunkName: "component---src-pages-people-jsx" */),
  "component---src-pages-reforestation-jsx": () => import("../src/pages/reforestation.jsx" /* webpackChunkName: "component---src-pages-reforestation-jsx" */),
  "component---src-pages-saplings-jsx": () => import("../src/pages/saplings.jsx" /* webpackChunkName: "component---src-pages-saplings-jsx" */),
  "component---src-pages-the-project-jsx": () => import("../src/pages/the-project.jsx" /* webpackChunkName: "component---src-pages-the-project-jsx" */),
  "component---src-pages-tools-jsx": () => import("../src/pages/tools.jsx" /* webpackChunkName: "component---src-pages-tools-jsx" */),
  "component---src-pages-trees-jsx": () => import("../src/pages/trees.jsx" /* webpackChunkName: "component---src-pages-trees-jsx" */),
  "component---src-pages-zecar-jsx": () => import("../src/pages/zecar.jsx" /* webpackChunkName: "component---src-pages-zecar-jsx" */)
}

